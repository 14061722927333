import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/docs/src/components/Guide.js"
import Button from 'components/Button.js';

export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>


<MDXTag name="p" components={components}>{`Template for producing PowerPoint or Keynote presentations.`}</MDXTag>
<div className="notice">
  We are working on a new version of this template for Q1 2019 that will have support for graphs, charts, image grids and more. If you have special requests, please contact a team member from Design or Marketing.
</div>

<Button
    download
    href="/HealthShare-Presentation.zip"
    label="Download presentation template"
/></MDXTag>

export const _frontmatter = {};

  